<template>
  <div v-loading.fullscreen.lock="loading" id="settings_verify_phone_number">
    <inner-page-title title="Verify phone"></inner-page-title>

    <!-- Already verified -->
    <div v-if="status == 'verified'" style="text-align: center" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'phone-alt']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You are good!</span>
      <p>Your phone number is verified, there's nothing you need to do.</p>
    </div>


    <!-- To add phone number -->
    <div v-if="status == 'not_verified'" style="text-align: center" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'phone-alt']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Let's start!</span>
      <p>Please enter your phone number with your country region. When you're finished click next. We will send you a one-time SMS message with a code.</p>

      <br>

      <el-select @change="onPhoneNumberInputChange(phone_number)" style="margin-bottom: 10px" v-model="country_code" placeholder="Select your country">
        <el-option
          v-for="code in country_codes"
          :key="code.code"
          :label="`${code.code}  (+${code.prefix})`"
          :value="code.code">
        </el-option>
      </el-select>

      <el-input inputmode="numeric" pattern="[0-9]*" placeholder="Phone number" v-model="phone_number" @input="onPhoneNumberInputChange"></el-input>

      <div style="margin-top: 30px">
        <el-button type="primary" @click="onVerifyPhoneNumberClick" class="secondary-color" round>Next&nbsp;&nbsp;<i class="el-icon-arrow-right"></i></el-button>
      </div>

    </div>


    <!-- Input code received -->
    <div v-if="status == 'code_needed'" style="text-align: center" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Check your phone!</span>
      <p>We have sent you a code to the number <span style="font-weight: 500">{{ user.data.phone_number | formattedPhoneNumber }}</span>. Enter it below when you receive it.</p>
    
      <br>

      <div class="verification-inputs">
        <input @input="onAddedDigitVerificationInput(0)" v-model="verification_code[0]" ref="verification_input_0" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
        <input @input="onAddedDigitVerificationInput(1)" v-model="verification_code[1]" ref="verification_input_1" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
        <input @input="onAddedDigitVerificationInput(2)" v-model="verification_code[2]" ref="verification_input_2" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
        <input @input="onAddedDigitVerificationInput(3)" v-model="verification_code[3]" ref="verification_input_3" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
        <input @input="onAddedDigitVerificationInput(4)" v-model="verification_code[4]" ref="verification_input_4" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
        <input @input="onAddedDigitVerificationInput(5)" v-model="verification_code[5]" ref="verification_input_5" inputmode="numeric" pattern="[0-9]*" type="text" maxlength="1"/>
      </div>

      <div style="margin-top: 30px; display: flex; align-items: center; justify-content: center;">
        <el-button type="info" @click="onCancelVerification" plain round>Cancel verification</el-button>
        <el-button type="primary" @click="onSubmitVerificationCode" class="secondary-color" round>Verify&nbsp;&nbsp;<i class="el-icon-arrow-right"></i></el-button>
      </div>
    </div>

  </div>
</template>

<script>
import { parsePhoneNumberFromString, formatIncompletePhoneNumber, getCountries, getCountryCallingCode } from 'libphonenumber-js';

export default {
  data: () => ({
    loading: false,
    country_code: 'IE',
    phone_number: '',
    verification_code: ['', '', '', '', '', ''],
    country_codes: getCountries().map(code => ({ code: code, prefix: getCountryCallingCode(code) }))
  }),

  methods: {
    onVerifyPhoneNumberClick() {
      let phone_number_lib = parsePhoneNumberFromString(this.phone_number, this.country_code);

      // If not valid phone number
      if (!phone_number_lib.isValid()) {
        return this.$message({ type: 'error', showClose: true, message: 'Phone number is not valid, make sure the country code is correct' });
      }

      this.loading = true;

      this.SharoAPI({ action: 'sendVerificationPhoneCode', data: { phone_number: phone_number_lib.number } }).then(result => {
        if (result.data.error) {
          return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        }

        // Set the first input number as focused
        setTimeout(() => {
          this.$refs.verification_input_0.focus();
        }, 500);
      }).finally(() => this.loading = false);
    },

    onSubmitVerificationCode() {
      let code = this.verification_code.join('');

      if (code.length < 5) {
        return this.$message({ type: 'error', showClose: true, message: 'Invalid code' });
      }

      this.loading = true;

      this.SharoAPI({ action: 'verifyPhoneCode', data: { code: code } }).then(result => {
        console.log(result);
        if (result.data.error) {
          return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        }

        // Update user claims
        this.$store.dispatch('checkUserClaims', true);
        
        return this.$message({ type: 'success', showClose: true, message: result.data.message });

      }).finally(() => this.loading = false);
    },

    onPhoneNumberInputChange(val) {
      this.phone_number = formatIncompletePhoneNumber(val, this.country_code);
    },

    onAddedDigitVerificationInput(val) {
      if (val > 4) {
        return;
      }

      // If no number
      if (this.verification_code[val] < '0' || this.verification_code[val] > '9') {
        return;
      }

      this.$refs[`verification_input_${Number(val) + 1}`].focus();
    },

    onCancelVerification() {
      this.loading = true;

      this.SharoAPI({ action: 'cancelVerificationPhoneCode', data: {} }).then(result => {
        if (result.data.error) {
          return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        }

        return this.$message({ type: 'success', showClose: true, message: result.data.message });
      }).finally(() => this.loading = false);
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    status() {
      if (this.$store.getters.user.data.phone_verified || (this.$store.getters.userClaims && this.$store.getters.userClaims.phone_verified)) {
        return 'verified';
      }

      if (this.$store.getters.user.data.phone_verification_uid) {
        return 'code_needed';
      }

      return 'not_verified'; //not_verified
    }
  },

  filters: {
    formattedPhoneNumber(val) {
      return parsePhoneNumberFromString(val).formatNational();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

  .container.settings-options {
    max-width: 600px;

    .form-item {
      margin-bottom: 10px;
    }
  }

  .image-container {
    border-radius: 50%;
    background-color: #d1f1ff;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin: auto;
    margin-bottom: 15px;
  }


  .verification-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -5px;

    input {
      width: 40px;
      height: 40px;
      font-size: 18px;
      text-align: center;
      margin: 5px;
      border: 1px solid rgba(0,0,0,0.2);
      border-radius: 5px;

      &:focus {
        border: 1.5px solid $secondary-color;
        outline: none;
      }
    }
  }

</style>