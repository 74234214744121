var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_verify_phone_number" }
    },
    [
      _c("inner-page-title", { attrs: { title: "Verify phone" } }),
      _vm.status == "verified"
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "phone-alt"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("You are good!")]
              ),
              _c("p", [
                _vm._v(
                  "Your phone number is verified, there's nothing you need to do."
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.status == "not_verified"
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "phone-alt"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("Let's start!")]
              ),
              _c("p", [
                _vm._v(
                  "Please enter your phone number with your country region. When you're finished click next. We will send you a one-time SMS message with a code."
                )
              ]),
              _c("br"),
              _c(
                "el-select",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { placeholder: "Select your country" },
                  on: {
                    change: function($event) {
                      return _vm.onPhoneNumberInputChange(_vm.phone_number)
                    }
                  },
                  model: {
                    value: _vm.country_code,
                    callback: function($$v) {
                      _vm.country_code = $$v
                    },
                    expression: "country_code"
                  }
                },
                _vm._l(_vm.country_codes, function(code) {
                  return _c("el-option", {
                    key: code.code,
                    attrs: {
                      label: code.code + "  (+" + code.prefix + ")",
                      value: code.code
                    }
                  })
                }),
                1
              ),
              _c("el-input", {
                attrs: {
                  inputmode: "numeric",
                  pattern: "[0-9]*",
                  placeholder: "Phone number"
                },
                on: { input: _vm.onPhoneNumberInputChange },
                model: {
                  value: _vm.phone_number,
                  callback: function($$v) {
                    _vm.phone_number = $$v
                  },
                  expression: "phone_number"
                }
              }),
              _c(
                "div",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "secondary-color",
                      attrs: { type: "primary", round: "" },
                      on: { click: _vm.onVerifyPhoneNumberClick }
                    },
                    [
                      _vm._v("Next  "),
                      _c("i", { staticClass: "el-icon-arrow-right" })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.status == "code_needed"
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "envelope-open-text"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("Check your phone!")]
              ),
              _c("p", [
                _vm._v("We have sent you a code to the number "),
                _c("span", { staticStyle: { "font-weight": "500" } }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("formattedPhoneNumber")(_vm.user.data.phone_number)
                    )
                  )
                ]),
                _vm._v(". Enter it below when you receive it.")
              ]),
              _c("br"),
              _c("div", { staticClass: "verification-inputs" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verification_code[0],
                      expression: "verification_code[0]"
                    }
                  ],
                  ref: "verification_input_0",
                  attrs: {
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                    type: "text",
                    maxlength: "1"
                  },
                  domProps: { value: _vm.verification_code[0] },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.verification_code, 0, $event.target.value)
                      },
                      function($event) {
                        return _vm.onAddedDigitVerificationInput(0)
                      }
                    ]
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verification_code[1],
                      expression: "verification_code[1]"
                    }
                  ],
                  ref: "verification_input_1",
                  attrs: {
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                    type: "text",
                    maxlength: "1"
                  },
                  domProps: { value: _vm.verification_code[1] },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.verification_code, 1, $event.target.value)
                      },
                      function($event) {
                        return _vm.onAddedDigitVerificationInput(1)
                      }
                    ]
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verification_code[2],
                      expression: "verification_code[2]"
                    }
                  ],
                  ref: "verification_input_2",
                  attrs: {
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                    type: "text",
                    maxlength: "1"
                  },
                  domProps: { value: _vm.verification_code[2] },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.verification_code, 2, $event.target.value)
                      },
                      function($event) {
                        return _vm.onAddedDigitVerificationInput(2)
                      }
                    ]
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verification_code[3],
                      expression: "verification_code[3]"
                    }
                  ],
                  ref: "verification_input_3",
                  attrs: {
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                    type: "text",
                    maxlength: "1"
                  },
                  domProps: { value: _vm.verification_code[3] },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.verification_code, 3, $event.target.value)
                      },
                      function($event) {
                        return _vm.onAddedDigitVerificationInput(3)
                      }
                    ]
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verification_code[4],
                      expression: "verification_code[4]"
                    }
                  ],
                  ref: "verification_input_4",
                  attrs: {
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                    type: "text",
                    maxlength: "1"
                  },
                  domProps: { value: _vm.verification_code[4] },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.verification_code, 4, $event.target.value)
                      },
                      function($event) {
                        return _vm.onAddedDigitVerificationInput(4)
                      }
                    ]
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.verification_code[5],
                      expression: "verification_code[5]"
                    }
                  ],
                  ref: "verification_input_5",
                  attrs: {
                    inputmode: "numeric",
                    pattern: "[0-9]*",
                    type: "text",
                    maxlength: "1"
                  },
                  domProps: { value: _vm.verification_code[5] },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.verification_code, 5, $event.target.value)
                      },
                      function($event) {
                        return _vm.onAddedDigitVerificationInput(5)
                      }
                    ]
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "30px",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info", plain: "", round: "" },
                      on: { click: _vm.onCancelVerification }
                    },
                    [_vm._v("Cancel verification")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "secondary-color",
                      attrs: { type: "primary", round: "" },
                      on: { click: _vm.onSubmitVerificationCode }
                    },
                    [
                      _vm._v("Verify  "),
                      _c("i", { staticClass: "el-icon-arrow-right" })
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }